import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormControl, Grid, Select, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { convertGeojsonToArrayOfObjects } from "../../../utils/helper-methods";
import GeoMap from "./GeoMap";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingTop: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxHeight: "calc(100vh - 55px)",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "2px 16px 10px 16px",
  borderRadius: "12px",
  border: "1px solid #e0e0e0",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
}));

const AddressInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
});

const LanguageSelect = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
  minWidth: "50px",
});

const AddButton = styled(Button)({
  backgroundColor: "#4285f4",
  borderRadius: "8px",
  textTransform: "uppercase",
  padding: "8.5px 24px",
  marginTop: "6px",
  "&:hover": {
    backgroundColor: "#3367d6",
  },
});

const DeleteButton = styled(IconButton)({
  color: "#d32f2f",
  padding: "8px",
  marginTop: "8px",
});

const languageOptions = [
  { code: "en", name: "English" },
  { code: "ar", name: "Arabic" },
  { code: "fa", name: "Persian" },
  { code: "es", name: "Spanish" },
  { code: "ur", name: "Urdu" },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  name2: Yup.string().required("Name2 is required"),
  pivotPoint: Yup.array().of(Yup.number()).notRequired(),
  formattedAddress: Yup.string().notRequired(),
  coordinates: Yup.array().min(1, "Coordinates are required").required(),
  type: Yup.string().required("Type is required"),
  apiChannel: Yup.string()
    .oneOf(["fribe", "google"], "Invalid api channel")
    .required("API channel is required"),
});

export default function MapDialog({
  open,
  handleClose,
  center,
  title,
  onAdd,
  editValues,
  setEditValues,
  viewValues,
  setViewValues,
  onEdit,
}) {
  // const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [isCustomAddressEnable, setIsCustomAddressEnable] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      pivotPoint: [],
      formattedAddress: "",
      coordinates: [],
      type: "",
      isPivotEnabled: false,
      apiChannel: "fribe",
      enableCustomAddress: false,
      customAddresses: {
        en: "",
      },
      // addDynamicValues: false,
      // dynamicValues: [
      //   {
      //     key: {
      //       en: "",
      //     },
      //     value: {
      //       en: "",
      //     },
      //     keyType: "String",
      //   },
      // ],
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  // const handleDynamicValuesToggle = (event) => {
  //   const isChecked = event.target.checked;
  //   formik.setFieldValue("addDynamicValues", isChecked);
  //   setShowDynamicForm(isChecked); // Show or hide the dynamic form based on the toggle
  // };

  const handleCustomValuesToggle = (event) => {
    const isChecked = event.target.checked;
    formik.setFieldValue("enableCustomAddress", isChecked);
    setIsCustomAddressEnable(isChecked);
  };

  function handleSubmit(values) {
    console.log("Form Values Before Submit:", values);
    const { isPivotEnabled } = values;
    if (editValues && open) {
      onEdit(editValues?.id, values);
      setEditValues(null);
      handleClose();
      return;
    }
    if (!isPivotEnabled) {
      delete values.pivotPoint;
    }

    onAdd(values);
    handleClose();
  }

  const handleDeleteAddress = (lang) => {
    const newCustomAddresses = { ...formik.values.customAddresses };
    delete newCustomAddresses[lang];
    formik.setFieldValue("customAddresses", newCustomAddresses);
  };

  const handleAddNewAddress = () => {
    if (formik.values.newLang && formik.values.newAddress) {
      formik.setFieldValue("customAddresses", {
        ...formik.values.customAddresses,
        [formik.values.newLang]: formik.values.newAddress,
      });
      formik.setFieldValue("newLang", "");
      formik.setFieldValue("newAddress", "");
    }
  };

  const usedLanguages = Object.keys(formik.values.customAddresses);
  const unusedLanguages = languageOptions.filter(
    (lang) => !usedLanguages.includes(lang.code)
  );

  // const handleAddEntry = () => {
  //   formik.setFieldValue("dynamicValues", [
  //     ...formik.values.dynamicValues,
  //     {
  //       key: {},
  //       value: {},
  //       keyType: "String",
  //     },
  //   ]);
  // };

  // const handleRemoveEntry = (entryIndex) => {
  //   const newValues = formik.values.dynamicValues.filter(
  //     (_, idx) => idx !== entryIndex
  //   );
  //   formik.setFieldValue("dynamicValues", newValues);
  // };

  // const handleAddLanguage = (entryIndex) => {
  //   const newValues = [...formik.values.dynamicValues];
  //   const currentEntry = newValues[entryIndex];

  //   const availableLanguages = languageOptions.filter(
  //     (lang) => !Object.keys(currentEntry.key).includes(lang.code)
  //   );

  //   if (availableLanguages.length > 0) {
  //     const newLang = availableLanguages[0].code;
  //     newValues[entryIndex] = {
  //       ...currentEntry,
  //       key: { ...currentEntry.key, [newLang]: "" },
  //       value: {
  //         ...currentEntry.value,
  //         [newLang]: currentEntry.keyType === "Array" ? [""] : "",
  //       },
  //     };
  //     formik.setFieldValue("dynamicValues", newValues);
  //   }
  // };

  // const handleRemoveLanguage = (entryIndex, langCode) => {
  //   const newValues = [...formik.values.dynamicValues];
  //   const currentEntry = newValues[entryIndex];

  //   delete currentEntry.key[langCode];
  //   delete currentEntry.value[langCode];

  //   formik.setFieldValue("dynamicValues", newValues);
  // };

  // const handleKeyTypeChange = (entryIndex, newType) => {
  //   const newValues = [...formik.values.dynamicValues];
  //   const currentEntry = newValues[entryIndex];

  //   const convertedValues = Object.entries(currentEntry.value).reduce(
  //     (acc, [lang, val]) => {
  //       acc[lang] =
  //         newType === "Array"
  //           ? Array.isArray(val)
  //             ? val
  //             : [val || ""]
  //           : Array.isArray(val)
  //           ? val[0] || ""
  //           : val;
  //       return acc;
  //     },
  //     {}
  //   );

  //   newValues[entryIndex] = {
  //     ...currentEntry,
  //     keyType: newType,
  //     value: convertedValues,
  //   };

  //   formik.setFieldValue("dynamicValues", newValues);
  // };

  // const handleAddArrayValue = (entryIndex, langCode) => {
  //   const newValues = [...formik.values.dynamicValues];
  //   const currentValues = newValues[entryIndex].value[langCode];

  //   newValues[entryIndex].value[langCode] = [
  //     ...(Array.isArray(currentValues) ? currentValues : [currentValues]),
  //     "",
  //   ];

  //   formik.setFieldValue("dynamicValues", newValues);
  // };

  // const handleLanguageChange = (entryIndex, oldLang, newLang) => {
  //   const entries = [...formik.values.dynamicValues];
  //   const entry = entries[entryIndex];

  //   const keyValue = entry.key[oldLang];
  //   const dataValue = entry.value[oldLang];

  //   delete entry.key[oldLang];
  //   delete entry.value[oldLang];

  //   entries[entryIndex] = {
  //     ...entry,
  //     key: { ...entry.key, [newLang]: keyValue },
  //     value: { ...entry.value, [newLang]: dataValue },
  //   };

  //   formik.setFieldValue("dynamicValues", entries);
  // };
  // const handleRemoveArrayValue = (entryIndex, langCode, valueIndex) => {
  //   const newValues = [...formik.values.dynamicValues];
  //   newValues[entryIndex].value[langCode] = newValues[entryIndex].value[
  //     langCode
  //   ].filter((_, idx) => idx !== valueIndex);

  //   formik.setFieldValue("dynamicValues", newValues);
  // };

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setEditValues(null);
      setViewValues(null);
      setIsCustomAddressEnable(false);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (viewValues && open) {
      const shouldShowDynamic = viewValues?.enableCustomAddress || false;
      setIsCustomAddressEnable(shouldShowDynamic);
      formik.setValues({
        name: viewValues?.name,
        name2: viewValues?.name2,
        formattedAddress: viewValues?.formattedAddress,
        coordinates: convertGeojsonToArrayOfObjects(viewValues),
        pivotPoint: viewValues?.pivotPoint,
        type: viewValues?.type,
        isPivotEnabled:
          viewValues?.pivotPoint && viewValues?.pivotPoint?.length > 0
            ? true
            : false,
        apiChannel: viewValues?.apiChannel || "fribe",
        enableCustomAddress: viewValues.enableCustomAddress,
        customAddresses: viewValues.customAddresses || {
          en: "",
        },
        // addDynamicValues: viewValues.addDynamicValues,
        // dynamicValues: viewValues?.dynamicValues || [
        //   {
        //     key: {
        //       en: "",
        //     },
        //     value: {
        //       en: "",
        //     },
        //     keyType: "String",
        //   },
        // ],
      });
      return;
    } else if (editValues && open) {
      const shouldShowDynamic = editValues?.enableCustomAddress || false;
      setIsCustomAddressEnable(shouldShowDynamic);
      formik.setValues({
        name: editValues?.name,
        name2: editValues?.name2,
        formattedAddress: editValues?.formattedAddress,
        coordinates: convertGeojsonToArrayOfObjects(editValues),
        pivotPoint: editValues?.pivotPoint,
        type: editValues?.type,
        enableCustomAddress: editValues.enableCustomAddress,
        isPivotEnabled:
          editValues?.pivotPoint && editValues?.pivotPoint?.length > 0
            ? true
            : false,
        apiChannel: editValues?.apiChannel || "fribe",
        customAddresses: editValues?.customAddresses || {
          en: "",
        },
      });
    }
    // eslint-disable-next-line
  }, [viewValues, editValues]);

  return (
    <BootstrapDialog
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 1,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box
          component="form"
          sx={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
            },
            "& .MuiFormControl-root": {
              marginTop: "8px",
            },
          }}
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              margin="normal"
              required
              id="name"
              label="Name"
              fullWidth
              name="name"
              autoFocus
              disabled={viewValues ? true : false}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin="normal"
              required
              id="name2"
              label="Name2"
              fullWidth
              name="name2"
              autoFocus
              disabled={viewValues ? true : false}
              value={formik.values.name2}
              onChange={formik.handleChange}
              error={formik.touched.name2 && Boolean(formik.errors.name2)}
              helperText={formik.touched.name2 && formik.errors.name2}
            />
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="formattedAddress"
              label="Formatted Address"
              type="text"
              id="formattedAddress"
              value={formik.values.formattedAddress}
              disabled={viewValues ? true : false}
              onChange={formik.handleChange}
              error={
                formik.touched.formattedAddress &&
                Boolean(formik.errors.formattedAddress)
              }
              helperText={
                formik.touched.formattedAddress &&
                formik.errors.formattedAddress
              }
            />
            <TextField
              select
              margin="normal"
              required
              fullWidth
              name="apiChannel"
              label="Api Channel"
              id="apiChannel"
              value={formik.values.apiChannel}
              onChange={formik.handleChange}
              error={
                formik.touched.apiChannel && Boolean(formik.errors.apiChannel)
              }
              helperText={formik.touched.apiChannel && formik.errors.apiChannel}
            >
              <MenuItem value="fribe">Fribe</MenuItem>
              <MenuItem value="google">Google</MenuItem>
            </TextField>
          </Stack>

          <Box sx={{ mt: 1 }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    name="enableCustomAddress"
                    checked={formik.values.enableCustomAddress}
                    onChange={handleCustomValuesToggle}
                    disabled={viewValues ? true : false}
                  />
                }
                label="Enable Custom Address"
                labelPlacement="start"
                sx={{ m: 0, justifyContent: "space-between", width: "100%" }}
              />
            </FormGroup>
          </Box>
          {isCustomAddressEnable && (
            <Box sx={{ maxWidth: "100%", paddingY: 2 }}>
              {Object.entries(formik.values.customAddresses).map(
                ([lang, address], index) => (
                  <StyledBox key={index}>
                    <Typography sx={{ minWidth: "40px", pt: "10px" }}>
                      {lang.toUpperCase()}:
                    </Typography>
                    <AddressInput
                      fullWidth
                      disabled={viewValues ? true : false}
                      value={address}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `customAddresses.${lang}`,
                          e.target.value
                        )
                      }
                      variant="outlined"
                      size="small"
                    />
                    <DeleteButton
                      disabled={viewValues ? true : false}
                      onClick={() => handleDeleteAddress(lang)}
                    >
                      <DeleteIcon />
                    </DeleteButton>
                  </StyledBox>
                )
              )}

              {!viewValues && (
                <Box
                  sx={{
                    padding: "2px 16px 10px 16px",
                    borderRadius: "12px",
                    border: "1px solid #e0e0e0",
                    marginBottom: "16px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <LanguageSelect size="small" fullWidth>
                        <Select
                          value={formik.values.newLang || ""}
                          onChange={(e) =>
                            formik.setFieldValue("newLang", e.target.value)
                          }
                          displayEmpty
                          renderValue={(value) => value || "Language"}
                          sx={{ height: "40px" }}
                        >
                          {unusedLanguages.map((lang) => (
                            <MenuItem key={lang.code} value={lang.code}>
                              {lang.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </LanguageSelect>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <AddressInput
                        fullWidth
                        placeholder="New Address"
                        value={formik.values.newAddress || ""}
                        onChange={(e) =>
                          formik.setFieldValue("newAddress", e.target.value)
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <AddButton
                        variant="contained"
                        onClick={handleAddNewAddress}
                        startIcon={<AddIcon />}
                        fullWidth
                      >
                        Add
                      </AddButton>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          )}
          {/* {showDynamicForm && (
            <Box sx={{ mt: 2 }}>
              {formik.values.dynamicValues.map((entry, entryIndex) => (
                <Paper
                  key={entryIndex}
                  sx={{
                    mb: 3,
                    p: 2,
                    border: 1,
                    borderColor: "divider",
                    borderRadius: 2,
                  }}
                >
                  <Stack spacing={2}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="subtitle1">
                        Value #{entryIndex + 1}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveEntry(entryIndex)}
                        disabled={viewValues}
                        sx={{ color: "error.main" }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                    <TextField
                      select
                      fullWidth
                      label="Value Type"
                      value={entry.keyType}
                      onChange={(e) =>
                        handleKeyTypeChange(entryIndex, e.target.value)
                      }
                      disabled={viewValues}
                      size="small"
                    >
                      {["String", "Array"].map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>
                    {Object.keys(entry.key).map((langCode) => (
                      <Paper
                        key={langCode}
                        sx={{
                          p: 2,
                          bgcolor: "background.paper",
                          borderRadius: 2,
                          position: "relative",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleRemoveLanguage(entryIndex, langCode)
                          }
                          disabled={viewValues}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 0,
                            color: "error.main",
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                        <Stack spacing={2}>
                          <TextField
                            select
                            label="Language"
                            value={langCode}
                            onChange={(e) =>
                              handleLanguageChange(
                                entryIndex,
                                langCode,
                                e.target.value
                              )
                            }
                            disabled={viewValues}
                            size="small"
                          >
                            {languageOptions
                              .filter(
                                (lang) =>
                                  lang.code === langCode ||
                                  !Object.keys(entry.key).includes(lang.code)
                              )
                              .map((lang) => (
                                <MenuItem key={lang.code} value={lang.code}>
                                  {lang.name} ({lang.code})
                                </MenuItem>
                              ))}
                          </TextField>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Key"
                                name={`dynamicValues[${entryIndex}].key.${langCode}`}
                                value={entry.key[langCode]}
                                onChange={formik.handleChange}
                                disabled={viewValues}
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              {entry.keyType === "Array" ? (
                                <Box>
                                  {entry.value[langCode]?.map(
                                    (val, valIndex) => (
                                      <Stack
                                        key={valIndex}
                                        direction="row"
                                        spacing={1}
                                        sx={{ mb: 1 }}
                                      >
                                        <TextField
                                          fullWidth
                                          label={`Value ${valIndex + 1}`}
                                          name={`dynamicValues[${entryIndex}].value.${langCode}[${valIndex}]`}
                                          value={val}
                                          onChange={formik.handleChange}
                                          disabled={viewValues}
                                          size="small"
                                        />
                                        {valIndex > 0 && (
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              handleRemoveArrayValue(
                                                entryIndex,
                                                langCode,
                                                valIndex
                                              )
                                            }
                                            disabled={viewValues}
                                            sx={{ color: "error.main" }}
                                          >
                                            <CloseIcon fontSize="small" />
                                          </IconButton>
                                        )}
                                      </Stack>
                                    )
                                  )}
                                  <Button
                                    startIcon={<AddIcon />}
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                      handleAddArrayValue(entryIndex, langCode)
                                    }
                                    sx={{ mt: 1 }}
                                    disabled={viewValues}
                                  >
                                    Add Value
                                  </Button>
                                </Box>
                              ) : (
                                <TextField
                                  fullWidth
                                  label="Value"
                                  name={`dynamicValues[${entryIndex}].value.${langCode}`}
                                  value={entry.value[langCode] || ""}
                                  onChange={formik.handleChange}
                                  disabled={viewValues}
                                  size="small"
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Stack>
                      </Paper>
                    ))}
                    <Button
                      startIcon={<AddIcon />}
                      variant="outlined"
                      onClick={() => handleAddLanguage(entryIndex)}
                      size="small"
                      disabled={
                        viewValues ||
                        languageOptions.length <= Object.keys(entry.key).length
                      }
                    >
                      Add Language
                    </Button>
                  </Stack>
                </Paper>
              ))}

              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={handleAddEntry}
                size="small"
                sx={{ mt: 2 }}
                disabled={viewValues}
              >
                Add New Value
              </Button>
            </Box>
          )} */}

          <Box sx={{ mt: 1 }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    name="isPivotEnabled"
                    checked={formik.values.isPivotEnabled}
                    {...formik.getFieldProps("isPivotEnabled")}
                    disabled={viewValues ? true : false}
                  />
                }
                label="Pivot Required"
                labelPlacement="start"
                sx={{ m: 0 }}
              />
            </FormGroup>
          </Box>

          <Divider />

          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ m: 0.2, p: 0.2 }}
            color="#adb5bd"
          >
            {viewValues
              ? "You are in view only mode"
              : "Click once to add pivot point and twice to draw polygon*."}
          </Typography>

          <div
            style={{
              marginTop: "8px",
            }}
          />
          <GeoMap
            formik={formik}
            center={center}
            paths={
              convertGeojsonToArrayOfObjects(editValues) ||
              convertGeojsonToArrayOfObjects(viewValues)
            }
            pivotPoint={formik.values?.pivotPoint}
            isPivotEnabled={formik.values.isPivotEnabled}
            isEditMode={editValues ? true : false}
          />
          {formik.touched.coordinates && formik.errors.coordinates && (
            <p
              style={{
                margin: 0,
                color: "#d32f2f",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 1.66,
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
              }}
            >
              {formik.errors.coordinates}
            </p>
          )}
          {!viewValues && (
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 1, alignSelf: "flex-end" }}
              >
                Submit
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
